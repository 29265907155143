import { format, parse } from "date-fns";
import {
  formatTime,
  table_col_keys,
} from "ui/pages/ZoomAttendance/ZoomAttendanceOfListing/components/CustomerAttendanceForListingPopup/tableConfig";
import { calculateTotalMeetingDuration } from "ui/pages/ZoomAttendance/ZoomAttendanceOfListing/ZoomAttendanceOfListing.helper";
import { handleInputDateFormat } from "utils/Utils";
import { _exporterBase } from "../_exporterBase";

const table_cols_header = {
  [table_col_keys.listing_name]: "Offering Name",
  [table_col_keys.seconds_attended]: "Minutes Attended",
  [table_col_keys.session_date]: "Date of Session",
  [table_col_keys.first_join_time]: "First Join Time",
  [table_col_keys.last_leave_time]: "Last Leave Time",
};

const table_cols_order = [
  table_col_keys.listing_name,
  table_col_keys.seconds_attended,
  table_col_keys.session_date,
  table_col_keys.first_join_time,
  table_col_keys.last_leave_time,
];

export const table_cols_value_formatter = {
  [table_col_keys.seconds_attended]: (val) =>
    calculateTotalMeetingDuration(val).toString(),
  [table_col_keys.session_date]: (val) =>
    format(parse(val, "dd-MM-yyyy", new Date()), handleInputDateFormat()),
  [table_col_keys.first_join_time]: formatTime,
  [table_col_keys.last_leave_time]: formatTime,
};

const defaultColsValueFormatter = (val) => val;

const formatValue = (record, keyToFormat) => {
  const formatFunc =
    table_cols_value_formatter[keyToFormat] || defaultColsValueFormatter;
  return formatFunc(record[keyToFormat]);
};

export const customerAttendanceForListingExporter = _exporterBase({
  responseMapFunction: (item) => {
    const obj = {};
    table_cols_order.forEach((col) => {
      obj[table_cols_header[col]] = formatValue(item, col);
    });
    return obj;
  },
  downloadedCSVFileName: "zoom listing customer activity",
});
