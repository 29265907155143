import { parseFileNameForUploadOrDownload } from "utils/fileUtils";

/**
 * Downloads a file from the given 'url' and rename
 * the downloaded file with the given 'name'
 *
 * @param {string} url - The URL of the file to download
 * @param {string} name - The name given to the downloaded file
 * @param {number} maxNameLength - Custom max length for name of file, default 10
 */
export const downloadFileWithCustomName = (url, name, maxNameLength = 10) => {
  try {
    new URL(url);
  } catch (err) {
    console.log("error occured while downloading file: Invalid url");
    return;
  }
  if (!name) {
    console.log("error occured while downloading file: Invalid name");
    return;
  }
  fetch(url, {
    headers: {
      "Content-Type": "application/octet-stream",
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const parsedFileName = parseFileNameForUploadOrDownload(
        name,
        maxNameLength
      );
      const a = document.createElement("a");
      const blobUrl = window.URL.createObjectURL(blob);
      a.href = blobUrl;
      a.download = parsedFileName;
      // Append the anchor tag to the body
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(blobUrl);
    })
    .catch((err) => {
      console.error("error occured while downloading file: ", err);
    });
};
