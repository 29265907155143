import { getAnswerText, handleDateFormat } from "utils/Utils";
import { is_empty } from "utils/validations";
import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";

export const exporter = (data) => {
  const dataForExport = data.map((data) => {
    let return_data = {
      "Customer Name": data.customer_name,
      "Customer Email": data.customer_email,
      "Customer Phone":
        is_empty(data.customer_phone) || data.customer_phone === "N/A"
          ? "N/A"
          : `${data.customer_country_code} ${data.customer_phone}`,
      "Answer Count": `${data?.answer_count || "N/A"}`,
      "Booked On": `${
        data.booked_on ? handleDateFormat(data.booked_on) : "N/A"
      }`,
    };
    if (data?.answer_count > 0 && !is_empty(data.answer_list)) {
      for (let item of data.answer_list) {
        if (item.question_text in return_data) continue;
        return_data[item.question_text] = getAnswerText(item);
      }
    }
    return return_data;
  });
  jsonExport(
    dataForExport,
    {
      headers: [
        "Customer Name",
        "Customer Email",
        "Customer Phone",
        "Answer Count",
        "Booked On",
      ], // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, "Ads Customers"); // download as 'posts.csv` file
    }
  );
};
