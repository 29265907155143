import { EXLY_SUPPORT_CONSTANTS } from "constants/exlySupport.constants";

/**
 * Opens the Exly Support WhatsApp URL in a new browser window in desktop and in mobile opens the whats app
 * Utilizes the support WhatsApp URL from EXLY_SUPPORT_CONSTANTS.
 * @returns {void}
 */
export const openExlySupportWhatsAppUrl = () => {
  // Open the support WhatsApp URL in a new browser window
  window.open(EXLY_SUPPORT_CONSTANTS.support_whatsApp_url, "_blank");
};
