import { calculateTotalMeetingDuration } from "ui/pages/ZoomAttendance/ZoomAttendanceOfListing/ZoomAttendanceOfListing.helper";
import { _exporterBase } from "../_exporterBase";

const exporter_config = [
  {
    field: "customer_name",
    headerName: "Customer Name",
  },
  {
    field: "attended",
    headerName: "Attended",
    valueFormatter: (record) => `${record.session_attended > 0 ? "Yes" : "No"}`,
  },
  {
    field: "total_meeting_duration",
    headerName: "Total Minutes Attended",
    valueFormatter: (record) =>
      calculateTotalMeetingDuration(record.total_meeting_duration).toString(),
  },
  {
    field: "customer_email",
    headerName: "Email",
  },
  {
    field: "customer_phone_numer",
    headerName: "Phone",
    valueFormatter: (record) =>
      record.customer_country_code + " " + record.customer_phone_numer,
  },
];

export const webinarAttendanceExporter = _exporterBase({
  responseMapFunction: (item) => {
    const obj = {};
    exporter_config.forEach((col) => {
      obj[col.headerName] = (
        col.valueFormatter ? col.valueFormatter(item) : item[col.field]
      ).toString();
    });
    return obj;
  },
  downloadedCSVFileName: "zoom listing attendance",
});
