import { downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";

export const _exporterBase = ({
  responseMapFunction,
  downloadedCSVFileName,
}) => {
  return (response, return_csv = false) => {
    return new Promise((resolve, reject) => {
      try {
        const dataForExport = response.map(responseMapFunction);
        jsonExport(dataForExport, (err, csv) => {
          if (return_csv) resolve(csv);
          downloadCSV(csv, downloadedCSVFileName); // downloads as `${downloadedCSVFileName}.csv` file
        });
      } catch (err) {
        reject(err);
      }
    });
  };
};
