import { api, dataProvider } from "data";

/**
 * @deprecated use `fetchOfferingDetails` in `src/hooks/useOfferings.js`
 */
export const fetchListingDetails = ({ listingUuid }) =>
  dataProvider
    .custom_request(`${api.get_listing_details}/${listingUuid}`)
    .then((apiResponse) => {
      if (apiResponse?.status === 200) return apiResponse;
      throw apiResponse;
    });
