import { CUSTOM_BLOCK_SEPARATOR } from "ui/pages/EmailDrip/EmailDrip.constants";

export const hasImage = (htmlString) => /<img[^>]+src="[^"]+"/.test(htmlString);

export const hasVideo = (htmlString) =>
  /<video[^>]+src="[^"]+"/.test(htmlString);

function replaceDollarSigns(inputStr) {
  const outputStr = inputStr.replace(/\${2}(.+?)\${2}/g, " $1 ");
  return outputStr.trim();
}

export const getPlainText = (htmlString) => {
  // Remove images and videos and their children
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;

  tempElement.querySelectorAll("img, video").forEach((node) => node.remove());

  // Remove all HTML tags to get a plain text string
  const plainText = tempElement.innerText;
  // handle for vaiables
  return replaceDollarSigns(plainText);
};

export const getEmailPreviewFromHtmlString = (htmlString, meta_info) => {
  const separatedBlock = htmlString.split(CUSTOM_BLOCK_SEPARATOR)[0];
  const plainText = getPlainText(separatedBlock);
  if (plainText) return plainText;
  if (meta_info?.type === "button") return "<Button>";
  if (meta_info?.type === "discount") return "<Discount>";
  if (meta_info?.type === "earlyBird") return "<Limited-Offer>";
  if (hasImage(separatedBlock)) return "<Image>";
  if (hasVideo(separatedBlock)) return "<Video>";
  return "";
};

export const getLinksUsedInHtml = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  const links = Array.from(doc.querySelectorAll("a"))
    .filter((link) => Boolean(link.textContent.trim()))
    .map((link) => ({
      link_text: link.textContent.trim(),
      hyperlink: link.href,
    }));

  return links;
};

/**
 * Modifies an HTML string containing an iframe element by setting the width to 100%
 * and the position to 'inherit' if the iframe is found.
 *
 * @param htmlString - The HTML string to be processed.
 * @returns The modified HTML string with iframe width set to 100% and position set to 'inherit',
 *          or the original HTML string if no iframe is found.
 */
export const handleIframeHtmlString = (htmlString) => {
  // Create a temporary element to parse the HTML string
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;

  // Find the first iframe element in the parsed HTML
  const iframeElement = tempElement.querySelector("iframe");
  if (iframeElement) {
    // handle wirdth and position
    iframeElement.style.width = "100%";
    iframeElement.style.position = "inherit";
    return tempElement.innerHTML;
  }
  // If no iframe is found, return the original HTML string
  return htmlString;
};
