import { is_empty } from "utils/validations";
import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";

export const exporter = (data) => {
  const dataForExport = data.map((data) => {
    let return_data = {
      "Customer Name": data.full_name,
      "Customer Email": data.email,
      Phone:
        is_empty(data.phone_number) || data.phone_number === "N/A"
          ? "N/A"
          : `${data.country_code} ${data.phone_number}`,
      "Reason For Contact": `${data?.reason || "N/A"}`,
    };

    return return_data;
  });
  jsonExport(
    dataForExport,
    {
      headers: [
        "Customer Name",
        "Customer Email",
        "Phone",
        "Reason For Contact",
      ], // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, "Ads Leads");
    }
  );
};
